.tier{
    text-align: center;
    padding-bottom: 150px;
    position: relative;

    .wrapper{
        width: 60%;
    }

    p{
        padding-bottom: 2rem;
        padding-top: 10vh;
        text-transform: uppercase;
    }

    h1{
        font-size: 4.8rem;
        padding-bottom: 4rem;
    }

    .moods{
        @include flex(row, center, stretch);
        flex-wrap: wrap;
        max-width: 700px;
        margin: 0 auto;

        li{
            margin: 0 2rem 3rem;
        }
    }

    // mascot image
    &__mascot{
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 10%;
        width: 200px;
        animation: subtleBounce 3s infinite;
        padding-bottom: 40px;

        &--side{
            right: 10%;
            top: 20vh;
            width: 180px;
            left: auto;
        }

        @media (prefers-reduced-motion) {
            animation: none;
        }
    }

    &__bottom-button{
        padding-top: 6vh;
        @include flex(row, center, center); 
    }

    &__top-button{
        // padding-top: 6rem;
        @include flex(row, flex-start, center); 
    }


    @media screen and (max-width: 800px){
        padding-bottom: 200px;
        padding-top: 200px;

        &__mascot{
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: auto;
            width: 150px;
            animation: subtleBounce 2s infinite;
            animation-timing-function: ease;

            &--side{
                top: 20px;
                bottom: auto;
                width: 180px;
            }
        }
    }

    @media screen and (max-width: 500px){
        .moods{
            padding-bottom: 4rem;
        }
    }

}

@keyframes subtleBounce{
    0%{
        transform: translateY(0px);
    }

    50%{
        transform: translateY(10px);
    }

    100%{
        transform: translateY(0px);
    }
}