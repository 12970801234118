.about{
    margin-bottom: 20rem;


    &__intro{
        @include flex(row, center, center);
        padding-bottom: 10rem;

        a{
            text-decoration: underline;
        }

        a:hover,
        a:focus{
            font-style: italic;
        }

        article{
            width: 40%;
            padding-left: 4rem;
            color: $font;
            font-size: 1.4rem;
        }

        h1{
            padding-bottom: 2rem;
            font-size: 1.6rem;
        }

        p{
            font-family: $bodyFont;
            text-transform: none;
            font-style: normal;
            font-weight: 400;
            letter-spacing: normal;
            padding-bottom: 1rem;
            line-height: 1.5;
        }
    }

    &__image{
        min-width: 250px;
        width: 400px;
    }

    .about__credits{
        text-align: center;
        // width: 80%;
        margin: 0 auto;
        font-size: 1.6rem;
        padding-bottom: 8rem;

        h2{
            color: $font;
            font-size: 1.6rem;
            padding-bottom: 4rem;
        }

        p{
            margin-bottom: 2rem;
        }

        &--contrib ul{
            columns: 3;
        }

        a{
            text-decoration: underline;
        }
    }


    @media screen and (max-width: 800px) {
        &__intro{
            @include flex(column, center, center);


            article{
                width: 100%;
                padding-left: 0rem;
                padding-top: 6rem;
            }

            h1{
                text-align: center;
            }
        }

        &__image{
            width: 250px;
        }

        .about__credits{
            &--contrib ul{
                columns: 1;
            }
        }
    }
}