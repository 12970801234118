.results{
    padding: 0rem 0 20rem;

    // image
    &__image{
        width: 50%;
        margin: 0 auto;
        padding-bottom: 6rem;
        
        img{
            border: 4px solid $accent;
            margin: 0 auto;
            background-color: $darkColour;
        }

        div{
            background-color: $darkColour;
            border: 4px solid $accent;
        }
    }

    // title
    &__title{
        color: $font;
        font-size: 1.6rem;
        text-align: center;
        padding-bottom: 4rem;
        max-width: 360px;
        margin: 0 auto;
    }

    &__film-name{
        text-align: center;
        color: $mainColour;
        @include shadow(6px, $accent);
        font-size: 9rem;
        padding-bottom: 2rem;

    }

    &__director{
        text-align: center;
        color: $font;
        text-transform: none;
        font-style: italic;
    }

    // content

    &__content{
        padding: 6rem 0;
        max-width: 750px;
        margin: 0 auto;

        a{
            text-decoration: underline;
        }

        a:hover,
        a:focus{
            font-style: italic;
        }

        p, li{
            font-family: $bodyFont;
            text-transform: none;
            font-style: normal;
            font-weight: 400;
            letter-spacing: normal;
            padding-bottom: 1rem;
            line-height: 1.5;
            color: $font;
            font-size: 1.6rem;
        }

        h3{
            color: $font;
            padding-bottom: 2rem;
            font-size: 1.6rem;
            padding-top: 2rem;
        }

        div{
            padding-bottom: 4rem;
        }

        ul{
            list-style: disc;
            margin-left: 3rem;
        }

        .center-col{
            text-align: center;
        }

        .three-col{
            padding: 2rem 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 2rem;

            p{
                padding-bottom: 2rem;
            }
        }

        span:not(.italic){
            margin-left: 1rem;
        }
    }

    .internal-img{
        margin-top: 3rem;

        p{
            text-align: center;
            padding-top: 1rem;
        }
    }

    .rainbow-list{
        list-style: none;

        li::before{
            content: "🌈";
            margin-right: 10px;
        }
    }

    &__bio {
        text-align: center;
        max-width: 750px;
        margin: 0 auto;
        line-height: 1.5;

        a{
            text-decoration: underline;
        }

        a:hover,
        a:focus{
            font-style: italic;
        }
    }


    // button

    &__button{
        padding-top: 12vh;
        @include flex(row, center, center);
    }

    &__top-button{
        margin-bottom: 4rem;
    }


    @media screen and (max-width: 800px){
        &__content{
            .three-col{
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &__image{
            width: 100%;
        }
    }

    @media screen and (max-width: 500px){
        
        &__film-name{
            font-size: 6rem;
        }
    }
}