// landing styles
.landing-page{
    text-align: center;
    padding-top: 20vh;

    &__logo{
        width: 260px;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    h1{
        margin-bottom: 50px;
    }

    @media screen and (max-width: 500px){
        
        padding-top: 10vh;
    }
}