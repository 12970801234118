footer{
    text-align: center;
    padding: 50px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .wrapper{
        @include flex(column, center, center);
        gap: 16px;
    }

    small{
        color: $font;
        font-style: italic;
        font-size: 1rem;
        grid-column: 2;
    }

    ul{
        @include flex(row, flex-start, center);
        color: $mainColour;
        font-size: 2rem;
        gap: 20px;

        a{
            display: inline-block;
            transition: all 0.3s ease;
        }

        a:focus,
        a:hover{
            transform: scale(1.2);
        }

    }

    @media screen and (max-width: 500px) {
        padding: 20px 0 30px;

        small{
            padding-bottom: 3rem;
        }
    }
}