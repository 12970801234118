// wrapper styles
.wrapper{
    max-width: 1140px;
    width: 80%;
    margin: 0 auto;
}

// body font styles
body{
    font-family: $mainFont;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.2rem;
    font-size: 1.2rem;
    background-color: $darkColour;
    background-image: url('../85CDC00108A81072.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

// main button
.b-main{
    font-size: 1.6rem;
    color: $mainColour;
    font-family: $mainFont;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    width: 180px;
    padding: 15px 0;
    @include shadow(2px, $mainColour);
    border: 2px solid $secondaryColour;
    box-shadow: inset 0px 0px 4px $secondaryColour;
    position: relative;
    transition: all 0.1s ease;

    &::before{
        content: '';
        display: block;
        transform: scale(0);
        height: 2px;
        width: 95%;
        background-color: $secondaryColour;
        position: absolute;
        bottom: -8px;
        left: 2%;
        transition: all 0.1s ease-in;
    }

    &::after{
        content: '';
        display: block;
        transform: scale(0);
        height: 2px;
        width: 90%;
        background-color: $secondaryColour;
        position: absolute;
        bottom: -14px;
        left: 4%;
        transition: all 0.1s ease-in;
    }

    &:hover,
    &:focus{

        top: -4px;
        transform: scale(1.05);

        &::after,
        &::before{
            transform: scale(1);

        }
    }

    
    
}

.b-pink{
    border: 2px solid $mainColour;
    padding: 10px;

    &:hover,
    &:focus{
        font-style: italic;
        border-color: $font;
        color: $font;
        @include shadow(2px, white);
        outline-color: $font;
    }
}

// header styles: remove default styles
h1,
h2,
h3,
h4{
    font-size: 1.2rem;
}

// pink class
.pink{
    color: $mainColour;
    @include shadow(2px, $mainColour);
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: 600;
}

// fancy text
.elegant{
    font-family: $fancyHeading;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
}

// white eccentric styles
.white-elegant{
    color: $font;
    @include shadow(5px, white);
}

// links etc to italic and underline
.pink-link:hover,
.pink-link:focus{
    font-style: italic;
    text-decoration: underline;
}

// for italics
span.italic, em{
    font-style: italic;
}

