// header
header{
    padding: 20px 0;
    outline: none;

    .wrapper{
        @include flex(row, space-between, center);
    }

    section{
        @include flex(row, flex-start, center);

        .header__image{
            width: 50px;
            margin-right: 40px;
        }
    }

    ul{
        @include flex(row, flex-start, center);

        li{
            margin-left: 20px;
        }
    
        li:last-of-type{
            margin-left: 40px;
            position: relative;
    
        }


    }

    // question mark button
    .header__question{
        height: 30px;
        width: 30px;
        border-radius: 50%;
        font-size: 1.4rem;
        @include flex(column, center, center);
        background-color: $mainColour;
        color: $altText;
        font-weight: 600;
        transition: all 0.2s ease;
        &:hover,
        &:focus{
            transform: scale(1.1);
        }
    }

    .header__instructions{
        position: absolute;
        top: 50px;
        right: -10px;
        display: block;
        padding: 20px;
        border: 2px solid $secondaryColour;
        border-radius: 5px;
        color: $mainColour;
        text-transform: none;
        letter-spacing: 0;
        width: 200px;
        font-weight: 600;
        font-size: 1.4rem;
        box-shadow: inset 0px 0px 4px $secondaryColour;
        text-shadow: 0px 0px 2px $mainColour;

        &::before{
            content: "";
            position: absolute;
            right: 12px;
            top: -10px;
            border-top: none;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-bottom: 10px solid $secondaryColour;
        }
    }

    @media screen and (max-width: 800px){
        section{
            .header__image{
                margin-right: 0px;
            }

            p{
                display: none;
            }
        }

        .header__instructions{
            background-color: $darkColour;
        }
    }
}